import './App.css';
import Webinar1 from './component/webinar/Webinar1.0';
import MetaPixel from './utlis/meta/MetaPixel';


function App() {

  return (
          
	  <>
		  <MetaPixel/>
		  <Webinar1 />
		  </>
		 
  );
}

export default App;
